<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template #actions>
        <v-icon v-if="!panelEnabled" color="grey">mdi-eye-off</v-icon>
      </template>
      <template #default="{ open }">
        <v-row no-gutters>
          <v-col cols="4" :class="!panelEnabled ? 'grey--text' : ''">Contact Info</v-col>
          <v-col cols="7">
            <v-switch v-if="open" v-model="flowEnabled" label="Enabled" class="mt-0 float-right" @click.stop="updatePanelData()" />
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <div>
            <input id="font_color" type="color" name="font_color" :value="panelData.font_color" class="pointer" @change="handleColorChange" />
            <label for="font_color" class="pl-2 pointer">Font Color</label>
          </div>

          <div class="mt-4">
            <input id="background_color" type="color" name="background_color" :value="panelData.background_color" class="pointer" @change="handleColorChange" />
            <label for="background_color" class="pl-2 pointer">Background Color</label>
          </div>

          <div class="mt-4">
            <input
              id="fields_font_color"
              type="color"
              name="fields_font_color"
              :value="panelData.fields_font_color"
              class="pointer"
              @change="handleColorChange"
            />
            <label for="fields_font_color" class="pl-2 pointer">Fields Font Color</label>
          </div>

          <div class="mt-4">
            <input
              id="fields_background_color"
              type="color"
              name="fields_background_color"
              :value="panelData.fields_background_color"
              class="pointer"
              @change="handleColorChange"
            />
            <label for="fields_background_color" class="pl-2 pointer">Fields Background Color</label>
          </div>

          <div class="mt-4">
            <input
              id="button_font_color"
              type="color"
              name="button_font_color"
              :value="panelData.button_font_color"
              class="pointer"
              @change="handleColorChange"
            />
            <label for="button_font_color" class="pl-2 pointer">Button Font Color</label>
          </div>

          <div class="mt-4">
            <input
              id="button_background_color"
              type="color"
              name="button_background_color"
              :value="panelData.button_background_color"
              class="pointer"
              @change="handleColorChange"
            />
            <label for="button_background_color" class="pl-2 pointer">Button Background Color</label>
          </div>

          <div class="mt-4">
            <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Header Image</legend>
            <ImageUploadForm
              :edit-mode="panelEnabled"
              :allow-image-deletion="true"
              :image-url="panelData.header_image"
              image-type="swayOfferContactInfoImage"
              class="mt-2"
              @image-deleted="handleHeaderImageChanged"
              @image-uploaded="handleHeaderImageChanged"
            />
          </div>

          <v-text-field
            v-model="title"
            :disabled="!panelEnabled"
            outlined
            label="Title"
            class="mt-6"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetTitle()"
            @keydown.enter="$event.target.blur()"
          />

          <v-text-field
            v-model="subtitle"
            :disabled="!panelEnabled"
            outlined
            label="Subtitle"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetSubtitle()"
            @keydown.enter="$event.target.blur()"
          />

          <SwayOfferSteps :steps="steps" :enabled="panelEnabled" :include-icon="true" :include-sub-title="true" title="Contact Info Steps" @save="saveSteps" />

          <legend :class="['v-label theme--light mt-2', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Contact Info Types</legend>

          <div v-for="(contactInfoType, index) in swayOfferFlowContactInfoTypes" :key="`swayOfferFlowContactInfoType-contact${index}`">
            <v-checkbox
              v-model="collectContactInfo"
              :disabled="!panelEnabled"
              :label="
                contactInfoType
                  .toLowerCase()
                  .replaceAll('_', ' ')
                  .replace(/\b\w/g, (c) => c.toUpperCase())
              "
              :value="contactInfoType"
              class="mt-2 ml-2"
              hide-details="auto"
              @change="updatePanelData()"
            />

            <div v-if="collectContactInfo.includes(contactInfoType)">
              <v-checkbox
                v-model="requiredContactInfo"
                label="Required"
                :value="contactInfoType"
                class="mt-0 mb-2 ml-10"
                hide-details="auto"
                @change="updatePanelData()"
              />

              <v-checkbox
                v-model="preventDuplicateContactInfo"
                label="Prevent Duplicates"
                :value="contactInfoType"
                class="mt-0 mb-2 ml-10"
                hide-details="auto"
                @change="updatePanelData()"
              />

              <v-checkbox
                v-if="contactInfoType === 'PHONE_NUMBER'"
                v-model="showDisclaimer"
                :disabled="!panelEnabled"
                label="Show Disclaimer"
                class="mt-0 mb-2 ml-10"
                hide-details="auto"
                @change="updatePanelData()"
              />
            </div>
          </div>

          <div>
            <v-checkbox
              :disabled="!panelEnabled"
              :input-value="collectCustomInfo"
              label="Custom Dropdown"
              class="mt-2 ml-2"
              hide-details="auto"
              @change="handleCollectCustomInfoToggled"
            />

            <div v-if="collectCustomInfo !== null" class="ml-12">
              <v-checkbox
                v-model="collectCustomInfo.required"
                :disabled="!panelEnabled"
                label="Required"
                class="mt-2"
                hide-details="auto"
                @change="updatePanelData()"
              />

              <v-text-field
                v-model="collectCustomInfo.title"
                :disabled="!panelEnabled"
                outlined
                label="Title"
                class="mt-6"
                @blur="updatePanelData()"
                @keydown.enter="$event.target.blur()"
              />

              <SwayOfferSteps
                :steps="collectCustomInfoChoices"
                :enabled="panelEnabled"
                title="Dropdown Choices"
                step-verb="Choice"
                @save="handleCollectCustomInfoChoiceChanged"
              />
            </div>
          </div>

          <div>
            <v-checkbox
              :disabled="!panelEnabled"
              :input-value="collectCustomText"
              label="Custom Text"
              class="mt-2 ml-2"
              hide-details="auto"
              @change="handleCollectCustomTextToggled"
            />

            <div v-if="collectCustomText !== null" class="ml-12">
              <v-checkbox
                v-model="collectCustomText.required"
                :disabled="!panelEnabled"
                label="Required"
                class="mt-2"
                hide-details="auto"
                @change="updatePanelData()"
              />

              <v-text-field
                v-model="collectCustomText.title"
                :disabled="!panelEnabled"
                outlined
                label="Title"
                class="mt-6"
                @blur="updatePanelData()"
                @keydown.enter="$event.target.blur()"
              />
            </div>
          </div>

          <div class="mt-4">
            <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Prevent Duplicates From Other Offers</legend>
            <v-switch v-model="preventDuplicatesFromOtherOffers" label="Enabled" class="mt-n6 float-right" @click.stop="updatePanelData()" />
          </div>

          <div class="mt-4">
            <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Send Voucher Via Contact</legend>
            <v-switch v-model="sendVoucherViaContact" label="Enabled" class="mt-n6 float-right" @click.stop="updatePanelData()" />
          </div>

          <div v-if="preventDuplicatesFromOtherOffers" class="mt-4 ml-4">
            <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Duplicate Timeout in Days</legend>
            <v-text-field
              ref="preventDuplicateTimeoutField"
              v-model="preventDuplicateTimeout"
              :disabled="!panelEnabled"
              outlined
              :rules="isNumber"
              :validate-on-blur="true"
              placeholder="never"
              class="mt-0"
              @blur="updatePanelData()"
              @keydown.enter="$event.target.blur()"
            />
          </div>

          <div class="mt-8">
            <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Footer Image</legend>
            <ImageUploadForm
              :edit-mode="panelEnabled"
              :allow-image-deletion="true"
              :image-url="panelData.footer_image"
              image-type="swayOfferContactInfoImage"
              class="mt-2"
              @image-deleted="handleFooterImageChanged"
              @image-uploaded="handleFooterImageChanged"
            />
          </div>

          <v-text-field
            v-model="button_text"
            :disabled="!panelEnabled"
            outlined
            label="Submit Button Text"
            class="mt-6"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetButtonText()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { SwayOfferFlowContactInfoType } from '@/models/SwayOffer'
import validationRules from '@/utils/validation'

import ImageUploadForm from '@/components/ImageUploadForm'
import SwayOfferSteps from '@/components/SwayOffers/SwayOfferSteps'

export default Vue.extend({
  name: 'SwayContactInfoFlowPanel',

  components: { SwayOfferSteps, ImageUploadForm },

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    panelEnabled: {
      type: Boolean,
      default: false,
    },
    panelData: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    flowEnabled: false,
    font_color: '',
    background_color: '',
    fields_font_color: '',
    fields_background_color: '',
    button_text: '',
    button_font_color: '',
    button_background_color: '',
    header_image: '',
    footer_image: '',
    title: null,
    subtitle: null,
    steps: [],
    collectCustomInfo: null,
    collectCustomText: null,
    collectContactInfo: [],
    requiredContactInfo: [],
    preventDuplicateContactInfo: [],
    showDisclaimer: false,
    preventDuplicatesFromOtherOffers: false,
    preventDuplicateTimeout: null,
    isNumber: validationRules.isIntegerRules,
    sendVoucherViaContact: false,
  }),

  computed: {
    ...mapGetters('swayoffer', ['contactInfoDataFlowDefaults']),
    swayOfferFlowContactInfoTypes() {
      return Object.keys(SwayOfferFlowContactInfoType)
    },
    collectCustomInfoChoices() {
      return this.collectCustomInfo.choices.map((choice) => ({ title: choice }))
    },
  },

  mounted: function () {
    this.flowEnabled = this.panelEnabled
    this.font_color = this.panelData.font_color || ''
    this.background_color = this.panelData.background_color || ''
    this.fields_font_color = this.panelData.fields_font_color || ''
    this.fields_background_color = this.panelData.fields_background_color || ''
    this.button_text = this.panelData.button_text || ''
    this.button_font_color = this.panelData.button_font_color || ''
    this.button_background_color = this.panelData.button_background_color || ''
    this.header_image = this.panelData.header_image || ''
    this.footer_image = this.panelData.footer_image || ''
    this.title = this.panelData.title || null
    this.subtitle = this.panelData.subtitle || null
    this.steps = this.panelData.steps || []
    this.collectCustomInfo = this.panelData.collect_custom_info || null
    this.collectCustomText = this.panelData.collect_custom_text || null
    this.collectContactInfo = this.panelData.collect_contact_info || []
    this.requiredContactInfo = this.panelData.required_contact_info || []
    this.preventDuplicateContactInfo = this.panelData.prevent_duplicate_contact_info || []
    this.showDisclaimer = this.panelData.show_disclaimer || true
    this.preventDuplicatesFromOtherOffers = this.panelData.prevent_duplicates_from_other_offers || false
    this.preventDuplicateTimeout = this.panelData.prevent_duplicate_timeout_in_days || undefined
    this.sendVoucherViaContact = this.panelData.send_voucher_via_contact || false
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOfferFlow']),

    handleColorChange(event) {
      const { id, value } = event.target

      this[id] = value
      this.updatePanelData()
    },

    handleHeaderImageChanged(event) {
      this.header_image = event.image_url
      this.updatePanelData()
    },

    handleFooterImageChanged(event) {
      this.footer_image = event.image_url
      this.updatePanelData()
    },

    handleCollectCustomInfoToggled(enabled) {
      this.collectCustomInfo = enabled ? { title: 'Title', choices: [], required: false } : null
      this.updatePanelData()
    },

    handleCollectCustomInfoChoiceChanged(choices) {
      this.collectCustomInfo.choices = [...choices.map((choice) => choice.title)]
      this.updatePanelData()
    },

    handleCollectCustomTextToggled(enabled) {
      this.collectCustomText = enabled ? { title: 'Title', required: false } : null
      this.updatePanelData()
    },

    resetTitle() {
      this.title = this.contactInfoDataFlowDefaults.title
      this.updatePanelData()
    },

    resetSubtitle() {
      this.subtitle = this.contactInfoDataFlowDefaults.subtitle
      this.updatePanelData()
    },

    saveSteps(newSteps) {
      this.steps = [...newSteps]
      this.updatePanelData()
    },

    resetButtonText() {
      this.button_text = this.contactInfoDataFlowDefaults.button_text
      this.updatePanelData()
    },

    updatePanelData() {
      this.requiredContactInfo = this.requiredContactInfo.filter((requiredType) => {
        return this.collectContactInfo.includes(requiredType)
      })

      this.preventDuplicateContactInfo = this.preventDuplicateContactInfo.filter((requiredType) => {
        return this.collectContactInfo.includes(requiredType)
      })

      if (this.preventDuplicatesFromOtherOffers && this.preventDuplicateTimeOut !== undefined && isNaN(parseInt(this.preventDuplicateTimeout))) {
        this.$toast.error(`Invalid duplicate timeout day number`)
        return
      }

      this.updateSwayOfferFlow({
        swayOfferId: this.swayOfferId,
        swayOfferFlow: {
          contact_info_enabled: this.flowEnabled,
          contact_info_data: {
            ...this.panelData,
            font_color: this.font_color,
            background_color: this.background_color,
            fields_font_color: this.fields_font_color,
            fields_background_color: this.fields_background_color,
            button_text: this.button_text,
            button_font_color: this.button_font_color,
            button_background_color: this.button_background_color,
            header_image: this.header_image,
            footer_image: this.footer_image,
            title: this.title,
            subtitle: this.subtitle,
            steps: this.steps,
            collect_custom_info: this.collectCustomInfo,
            collect_custom_text: this.collectCustomText,
            collect_contact_info: this.collectContactInfo,
            required_contact_info: this.requiredContactInfo,
            prevent_duplicate_contact_info: this.preventDuplicateContactInfo,
            prevent_duplicates_from_other_offers: this.preventDuplicatesFromOtherOffers,
            prevent_duplicate_timeout_in_days: parseInt(this.preventDuplicateTimeout),
            show_disclaimer: this.showDisclaimer,
            send_voucher_via_contact: this.sendVoucherViaContact,
          },
        },
      })
    },
  },
})
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
