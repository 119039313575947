<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>
      <template #actions>
        <v-icon v-if="!panelEnabled" color="grey">mdi-eye-off</v-icon>
      </template>
      <template #default="{ open }">
        <v-row no-gutters>
          <v-col cols="4" :class="!panelEnabled ? 'grey--text' : ''">Screenshot</v-col>
          <v-col cols="7">
            <v-switch v-if="open" v-model="flowEnabled" label="Enabled" class="mt-0 float-right" @click.stop="updatePanelData()" />
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="instructionsTitle"
            :disabled="!panelEnabled"
            append-icon="mdi-restore"
            outlined
            label="Instructions Title"
            @blur="updatePanelData()"
            @click:append="resetInstructionsTitle()"
            @keydown.enter="$event.target.blur()"
          />

          <v-list flat class="mt-n1">
            <SwayOfferSteps
              :steps="instructions"
              :default-steps="claimDataFlowDefaults.instructions"
              :enabled="panelEnabled"
              title="Instructions"
              @save="saveInstructions"
            />
          </v-list>

          <v-radio-group
            v-model="reviewProof"
            :disabled="!panelEnabled"
            label="Should the screenshot be reviewed by an admin?"
            class="mt-n1"
            @change="updatePanelData()"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>

          <v-radio-group
            v-model="requireSocialPost"
            :disabled="!panelEnabled"
            label="Should the screenshot be a social post?"
            class="mt-4"
            @change="updatePanelData()"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>

          <div v-if="!requireSocialPost" class="mt-6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="stepOneTitle"
                  :disabled="!panelEnabled"
                  outlined
                  label="Step One Title"
                  @blur="updatePanelData()"
                  @keydown.enter="$event.target.blur()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="stepOneText"
                  :disabled="!panelEnabled"
                  outlined
                  label="Step One Text"
                  @blur="updatePanelData()"
                  @keydown.enter="$event.target.blur()"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import SwayOfferSteps from '@/components/SwayOffers/SwayOfferSteps'

export default Vue.extend({
  name: 'SwayScreenshotFlowPanel',

  components: { SwayOfferSteps },

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    panelEnabled: {
      type: Boolean,
      default: false,
    },
    panelData: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    flowEnabled: false,
    instructionsTitle: null,
    instructions: [],
    newInstruction: '',
    stepOneTitle: null,
    stepOneText: null,
    requireSocialPost: true,
    reviewProof: false,
    autoCheckProofs: false,
    extraWordCheck: [],
    newExtraWord: null,
    extraWordTitle: null,
    extraWordDescription: null,
  }),

  computed: {
    ...mapGetters('swayoffer', ['claimDataFlowDefaults']),
  },

  mounted: function () {
    this.flowEnabled = this.panelEnabled
    this.instructionsTitle = this.panelData.instructions_title || null
    this.instructions = this.panelData.instructions || []
    this.stepOneTitle = this.panelData.step_one_title || null
    this.stepOneText = this.panelData.step_one_text || null
    this.requireSocialPost = this.panelData.require_social_proof || false
    this.reviewProof = this.panelData.review_proof || false
    this.autoCheckProofs = this.panelData.auto_check_proofs || false
    this.extraWordCheck = this.panelData.extra_word_check || []
    this.extraWordTitle = this.panelData.extra_word_title || null
    this.extraWordDescription = this.panelData.extra_word_description || null
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOfferFlow']),

    resetInstructionsTitle() {
      this.instructionsTitle = this.claimDataFlowDefaults.instructions_title
      this.updatePanelData()
    },

    saveInstructions(newInstructions) {
      this.instructions = [...newInstructions]
      this.updatePanelData()
    },

    updatePanelData() {
      this.updateSwayOfferFlow({
        swayOfferId: this.swayOfferId,
        swayOfferFlow: {
          claim_enabled: this.flowEnabled,
          claim_data: {
            ...this.panelData,
            instructions_title: this.instructionsTitle,
            instructions: this.instructions,
            step_one_title: this.stepOneTitle,
            step_one_text: this.stepOneText,
            require_social_proof: this.requireSocialPost,
            review_proof: this.reviewProof,
            auto_check_proofs: this.autoCheckProofs,
            extra_word_check: this.extraWordCheck,
            extra_word_title: this.extraWordTitle,
            extra_word_description: this.extraWordDescription,
          },
        },
      })
    },
  },
})
</script>
